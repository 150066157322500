<template>
  <div id="unggas-nasional-data-distribusi-doc-list" data-app>

    <!--begin::Engage Widget 1-->
    <div class="card card-custom card-stretch gutter-b">
        <div class="card-body d-flex p-0 bg-white">
            <div class="flex-grow-1 p-8 card-rounded bgi-no-repeat d-flex align-items-center"
                style="background-position: right bottom; background-size: auto 100%; background-image: url(media/svg/humans/custom-8.svg)">

                <div class="row w-100">
                    <div class="col-12 col-sm-9">
                        <button 
                            @click="$router.push('/unggas-nasional/distribusi')" 
                            type="button" 
                            class="btn btn-link-primary font-weight-bolder px-8 font-size-lg mb-2"
                            v-b-tooltip.hover title="List Farm"
                        >
                            <span class="svg-icon svg-icon-lg">
                                <!--begin::Svg Icon-->
                                <inline-svg src="media/svg/icons/Navigation/Angle-double-left.svg" />
                                <!--end::Svg Icon-->
                            </span>
                            List Hatchery
                        </button>
                        <div class="separator separator-dashed separator-border-2 mb-5"></div>
                        <h4 class="text-danger font-weight-bolder">Search List</h4>
                        <div class="d-flex flex-center py-2 px-6 bg-light rounded">
                            <span class="svg-icon svg-icon-lg svg-icon-info">
                                <!--begin::Svg Icon-->
                                <inline-svg src="media/svg/icons/General/Search.svg" />
                                <!--end::Svg Icon-->
                            </span>
                            <input type="text" v-model="search" class="form-control border-0 font-weight-bold pl-2 bg-light" placeholder="Search Your List">
                        </div>
                    </div>
                    <div class="col-12 col-sm-3">

                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--end::Engage Widget 1-->

    <div class="card card-custom gutter-b">
      <!--begin::Header-->
      <div class="card-header border-0 py-5">
          <h3 class="card-title align-items-start flex-column">
              <span class="card-label font-weight-bolder text-dark">List Distribusi</span>
              <span class="text-muted mt-3 font-weight-bold font-size-sm">Data Distribusi Ayam Ras</span>
          </h3>
          <div class="card-toolbar">
              <a @click="$router.push(`/unggas-nasional/distribusi/riwayat/${idH}`)" class="btn btn-primary font-weight-bolder font-size-sm mr-2">
                <span class="svg-icon svg-icon-md svg-icon-white">
                    <!--begin::Svg Icon-->
                    <inline-svg src="media/svg/icons/Home/Book-open.svg" />
                    <!--end::Svg Icon-->
                </span>
                Riwayat
              </a>
              <a @click="$router.push(`/unggas-nasional/distribusi/send-doc/add/${idH}`)" class="btn btn-success font-weight-bolder font-size-sm">
                <span class="svg-icon svg-icon-md svg-icon-white">
                    <!--begin::Svg Icon-->
                    <inline-svg src="media/svg/icons/Layout/Layout-grid.svg" />
                    <!--end::Svg Icon-->
                </span>
                Tambah Distribusi
              </a>
          </div>
      </div>
      <!--end::Header-->

      <!--begin::Body-->
      <div class="card-body pt-0 pb-3">
          <!--begin::Table-->
          <div>
              <table class="table table-head-custom table-head-bg table-borderless table-vertical-center">
                  <thead>
                      <tr class="text-uppercase">
                          <th @click="sort('customer')" class="pl-7 cursor-pointer">
                              <span :class="{ 'text-primary': currentSort == 'customer' }">Distribusi</span>
                              <span v-if="currentSort == 'customer' && currentSortDir == 'asc'" class="svg-icon svg-icon-sm svg-icon-primary">
                                  <!--begin::Svg Icon-->
                                  <inline-svg src="media/svg/icons/Navigation/Down-2.svg"/>
                                  <!--end::Svg Icon-->
                              </span>
                              <span v-if="currentSort == 'customer' && currentSortDir == 'desc'" class="svg-icon svg-icon-sm svg-icon-primary">
                                  <!--begin::Svg Icon-->
                                  <inline-svg src="media/svg/icons/Navigation/Up-2.svg"/>
                                  <!--end::Svg Icon-->
                              </span>
                          </th>
                          <th @click="sort('jumlah')" class="pl-7 cursor-pointer d-none d-sm-table-cell">
                              <span :class="{ 'text-primary': currentSort == 'jumlah' }">Jumlah</span>
                              <span v-if="currentSort == 'jumlah' && currentSortDir == 'asc'" class="svg-icon svg-icon-sm svg-icon-primary">
                                  <!--begin::Svg Icon-->
                                  <inline-svg src="media/svg/icons/Navigation/Down-2.svg"/>
                                  <!--end::Svg Icon-->
                              </span>
                              <span v-if="currentSort == 'jumlah' && currentSortDir == 'desc'" class="svg-icon svg-icon-sm svg-icon-primary">
                                  <!--begin::Svg Icon-->
                                  <inline-svg src="media/svg/icons/Navigation/Up-2.svg"/>
                                  <!--end::Svg Icon-->
                              </span>
                          </th>
                          <th @click="sort('status')" class="pl-7 cursor-pointer d-none d-md-table-cell">
                              <span :class="{ 'text-primary': currentSort == 'status' }">Status</span>
                              <span v-if="currentSort == 'status' && currentSortDir == 'asc'" class="svg-icon svg-icon-sm svg-icon-primary">
                                  <!--begin::Svg Icon-->
                                  <inline-svg src="media/svg/icons/Navigation/Down-2.svg"/>
                                  <!--end::Svg Icon-->
                              </span>
                              <span v-if="currentSort == 'status' && currentSortDir == 'desc'" class="svg-icon svg-icon-sm svg-icon-primary">
                                  <!--begin::Svg Icon-->
                                  <inline-svg src="media/svg/icons/Navigation/Up-2.svg"/>
                                  <!--end::Svg Icon-->
                              </span>
                          </th>
                          <th></th>
                      </tr>
                  </thead>
                  <tbody v-if="!isLoading">
                      <tr v-for="(val, key) in listDoc" :key="key">
                          <td class="pl-0 py-8">
                              <div class="d-flex align-items-center">
                                  <div class="symbol symbol-50 flex-shrink-0 mr-4">
                                      <div class="symbol-label">
                                        <span v-if="val.peringatan" v-b-tooltip.hover :title="val.peringatan" :id="`peringatan-${key}`" class="cursor-pointer svg-icon svg-icon-lg svg-icon-danger mr-2 m-0">
                                            <!--begin::Svg Icon-->
                                            <inline-svg src="media/svg/icons/Code/Warning-1-circle.svg"/>
                                            <!--end::Svg Icon-->
                                        </span> 
                                      </div>
                                  </div>

                                  <div>
                                      <a href="#" class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">{{ val.customer }}</a>
                                      <span class="text-muted font-weight-bold d-block">Prov. {{ val.provinsi.uraian }} {{ val.kabupaten.uraian }} Kec. {{ val.kecamatan.uraian }} Ds. {{ val.desa.uraian }}</span>
                                  </div>
                              </div>
                          </td>
                          <td class="d-none d-sm-table-cell">
                              <span class="text-dark-75 font-weight-bolder d-block font-size-lg">
                                  {{ val.rincian_sum_jumlah | nominal }}
                              </span>
                              <span class="text-muted font-weight-bold">
                                  ekor
                              </span>
                          </td>
                          <td class="d-none d-md-table-cell">
                              <b-badge :variant="val.sts.color">{{ val.sts.uraian }}</b-badge>
                          </td>
                          <td class="text-right pr-0">
                            <b-dropdown size="sm" variant="hover-primary" class="btn-light rounded">
                                <template v-slot:button-content>
                                    <span class="svg-icon svg-icon-md svg-icon-primary">
                                        <!--begin::Svg Icon-->
                                        <inline-svg src="media/svg/icons/Code/Compiling.svg"/>
                                        <!--end::Svg Icon-->
                                    </span>
                                    <span class="d-none d-sm-inline">Action</span>
                                </template>                                        
                                <b-dropdown-item @click="$router.push(`/unggas-nasional/distribusi/${idH}/${val._id}`)">
                                    <span class="bg-light rounded cursor-pointer svg-icon svg-icon-sm svg-icon-danger p-2 mr-2 m-0">
                                        <!--begin::Svg Icon-->
                                        <inline-svg src="media/svg/poultry-farm/kandang.svg"/>
                                        <!--end::Svg Icon-->
                                    </span>
                                    <span class="align-self-center">Detail</span>
                                </b-dropdown-item>  
                                <b-dropdown-divider></b-dropdown-divider>                            
                                <b-dropdown-item @click="openDialogForm('FormEditSendDoc', val)">
                                    <span class="bg-light rounded cursor-pointer svg-icon svg-icon-primary p-2 mr-2 m-0">
                                        <!--begin::Svg Icon-->
                                        <inline-svg src="media/svg/icons/Design/Edit.svg"/>
                                        <!--end::Svg Icon-->
                                    </span>
                                    <span class="align-self-center">Edit</span>
                                </b-dropdown-item>
                                <b-dropdown-item @click="deletePopupData(val)">
                                    <span class="bg-light rounded cursor-pointer svg-icon svg-icon-primary p-2 mr-2 m-0">
                                        <!--begin::Svg Icon-->
                                        <inline-svg src="media/svg/icons/Home/Trash.svg"/>
                                        <!--end::Svg Icon-->
                                    </span>
                                    <span class="align-self-center">Delete</span>
                                </b-dropdown-item>
                            </b-dropdown>
                          </td>
                      </tr>
                  </tbody>
                  <tbody v-else>
                      <tr>
                          <td class="p-3">
                              <ContentLoader height="20"></ContentLoader>
                          </td>
                          <td class="d-none d-sm-table-cell p-3">
                              <ContentLoader height="20"></ContentLoader>
                          </td>
                          <td class="d-none d-md-table-cell p-3">
                              <ContentLoader height="20"></ContentLoader>
                          </td>
                          <td class="d-none d-md-table-cell p-3">
                              <ContentLoader height="20"></ContentLoader>
                          </td>
                      </tr>
                      
                      <tr>
                          <td class="p-3">
                              <ContentLoader height="20"></ContentLoader>
                          </td>
                          <td class="d-none d-sm-table-cell p-3">
                              <ContentLoader height="20"></ContentLoader>
                          </td>
                          <td class="d-none d-md-table-cell p-3">
                              <ContentLoader height="20"></ContentLoader>
                          </td>
                          <td class="d-none d-md-table-cell p-3">
                              <ContentLoader height="20"></ContentLoader>
                          </td>
                      </tr>
                  </tbody>
              </table>
          </div>
          <!--end::Table-->
      </div>
      <!--end::Body-->
      <div class="card-footer">
        <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="center"></b-pagination>
      </div>

  </div>

    <v-dialog v-model="dialogDetail" persistent scrollable max-width="500px">
      <component v-if="curComponent" :sdata="sdataForm" @close-modal="closeDialogForm" v-bind:is="curComponent"></component>
    </v-dialog>

    <v-dialog
    v-model="dialogDelete"
    max-width="300">
        <v-card>
            <v-card-title class="headline">Hapus data</v-card-title>

            <v-card-text>
                Apakah anda yakin akan menghapus data <b>{{(dataHapus ? `Distribusi ke ${dataHapus.customer}` : '')}}</b> ? data yang dihapus tidak dapat dikembalikan lagi
            </v-card-text>

            <v-card-actions>
            <v-spacer></v-spacer>

            <b-button
            variant="link-success"
            class="m-3"
            @click="dialogDelete = false">
                BATAL
            </b-button>

            <b-button
            variant="link-danger"
            class="m-3"
            @click="onDelete">
                HAPUS
            </b-button>
            </v-card-actions>
        </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { ContentLoader } from 'vue-content-loader'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import DistribusiService from "@/core/services/api/nasional/distribusi.service";
import ErrorService from "@/core/services/error.service";

import FormEditSendDoc from "@/view/pages/nasional/distribusi/form/send-doc/_Edit.vue";

import VueContext from 'vue-context';
import 'vue-context/src/sass/vue-context.scss';

export default {
  name: "unggas-nasional-data-distribusi-doc-list",
  props: {
    idH: {
      required: true
    }
  },
  data() {
    return {
      search: '',
      dialogDetail: false,
      curComponent: null,
      sdataForm: null,
      isLoading: true,
      currentPage: 1,
      perPage: 8,
      items: [],
      currentSort:'customer',
      currentSortDir:'asc',
      dialogDelete: false,
      dataHapus: null,
    }
  },  
  components: {
    VueContext,
    ContentLoader,
    FormEditSendDoc,
  },    
  computed: {
    ...mapGetters(["currentUser"]),
    dateView() {
      return (this.formChickin.tanggal.length > 0 ? this.formChickin.tanggal.join(' ~ ') : 'Silahkan klik untuk pilih tanggal')
    },
    filteredList() {
        const result = this.items.filter((row, index) => {
            return row.customer.toLowerCase().includes(this.search.toLowerCase())
        })
        return result
    },
    listDoc() {
        let sort = this.filteredList.slice(0).sort((a,b) => {
            let modifier = 1;
            if(this.currentSortDir === 'desc') modifier = -1;
            if(a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
            if(a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
            return 0;
        })

        const result = sort.filter((row, index) => {
            let start = (this.currentPage-1)*this.perPage;
            let end = this.currentPage*this.perPage;
            if(index >= start && index < end) return true;
        })
        return result
    },
    totalRows() {
        if (!this.filteredList) {
            return 1
        }
        return this.filteredList.length
    },
  },
  methods: { 
    sort(s) {
        //if s == current sort, reverse
        if(s === this.currentSort) {
        this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
        }
        this.currentSort = s;
    },
    openDialogForm(name, data) {
      this.curComponent = name
      this.sdataForm = data
      this.dialogDetail = true
    },
    closeDialogForm(res) {
      if (res.status) {
        if (res.refresh) {
            this.getData()    
        }
        this.curComponent = null
        this.sdataForm = null
        this.dialogDetail = false
      }
    },
    getData () {
      DistribusiService.getSendDocList({hatchery: this.idH})
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                this.items = []
                this.isLoading = false
                return;
            }
            this.items = res.data.data
            this.isLoading = false
        })
        .catch((err) => {
          this.items = []
          this.isLoading = false
          return ErrorService.status(err)
        })
    },
    deletePopupData(val) {
        this.dataHapus = val
        this.dialogDelete = true
    },
    onDelete () {
        if (! this.dataHapus) {
            return
        }
        DistribusiService.deleteSendDoc({ _hid: this.idH, distribusi: this.dataHapus._id })
        .then((res) => {
            if (res.data.status) { 
            this.getData(this.currentPage)              
            }
            ErrorService.message(res.data)
            this.dialogDelete = false
            this.dataHapus = null
        })
        .catch((err) => {
            this.dialogDelete = false
            this.dataHapus = null
            return ErrorService.status(err)
        })
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Distribusi DOC" }]);
    if (!this.currentUser.menu.nasional_distribusi) {
      return this.$router.push({ name: 'dashboard'}).catch(()=>{})
    }
    this.getData()
  },
};
</script>

<style lang="scss">
#unggas-nasional-data-distribusi-doc-list {
  .b-table-stacked-sm {
    tbody {
      tr { 
        [data-label] > div {
          width: calc(100% - 25%);
        }
        [data-label]::before {
          width: 25% !important;
        }
      }
    }
  }
}
</style>